import React, { useEffect, useState, useParams } from 'react';
import { withRouter } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { Button, Form, FormLabel } from 'react-bootstrap';
import Api from '../Services/Api';
import Alert from '../Services/Alert';
import ViewGameRatioColor from './ViewGameRatioColor';
import UpdateColorRatioSetting from './UpdateColorRatioSetting';
import MoneySetting from './MoneySetting';
import IncMsg from './IncMsg';

export const Setting = (props) => {
    const [settingInfo, setSettingInfo] = useState({
        pg_is_card: "",
        pg_is_net: "",
        pg_is_upi: "",
        pg_is_googlepay: "",
        pg_is_phonepay: "",
    });
    const {
        register,
        reset,
        formState: { errors },
        handleSubmit,
    } = useForm({
        mode: "onChange",
        defaultValues: settingInfo
    });

    const [gameSettingInfo, setGameSettingInfo] = useState({
        is_market: "",
        is_colors: "",
        game_duration: "",
    });
    const {
        register: register2,
        reset: reset2,
        formState: { errors: errors2 },
        handleSubmit: handleSubmit2,
    } = useForm({
        mode: "onChange",
        defaultValues: gameSettingInfo
    });

    const [profitRatio, setProfitRatio] = useState('');
    const [isMarket, setIsMarket] = useState('');
    const [isColors, setIsColors] = useState('');
    const [gameDuration, setGameDuration] = useState('');
    let [paymentGatewayList, setPaymentGatewayList] = useState('');
    let [success, setSuccess] = useState('');
    let [fail, setFail] = useState('');

    const onSubmit = (data) => {
        //console.log(JSON.stringify(data));
        Api.post('update_setting', data).then((res) => res.data).then(res => {
            if (res.status) {
                Alert.success(res.message);
            } else {
                Alert.error(res.message);
            }
        }).catch(error => {
            Alert.error(error.message);
        });
    };

    const onSubmitGame = (data) => {
        //console.log(JSON.stringify(data));
        Api.post('update_game_setting', data).then((res) => res.data).then(res => {
            if (res.status) {
                Alert.success(res.message);
            } else {
                Alert.error(res.message);
            }
        }).catch(error => {
            Alert.error(error.message)
        });
    };

    const handleChangeIsMarket = (e) => {
        let gameData = {};
        if (e.target.value == 0) {
            if (isColors == 0) {
                gameData = {
                    is_market: '0',
                    is_colors: '1' // change
                };
                setIsColors('1');
            } else {
                gameData = {
                    is_market: '0',
                    is_colors: isColors
                };
            }
        } else {
            gameData = {
                is_market: '1',
                is_colors: isColors
            };
        }
        gameData['game_duration'] = gameDuration;
        setIsMarket(e.target.value);
        setGameSettingInfo(gameData);
        reset2(gameData);
    };

    const handleChangeIsColor = (e) => {
        let gameData = {};
        if (e.target.value == 0) {
            if (isMarket == 0) {
                gameData = {
                    is_market: '1', //change
                    is_colors: '0'
                };
                setIsMarket('1');
            } else {
                gameData = {
                    is_market: isMarket,
                    is_colors: '0'
                };
            }
        } else {
            gameData = {
                is_market: isMarket,
                is_colors: '1'
            };
        }
        gameData['game_duration'] = gameDuration;
        setIsColors(e.target.value);
        setGameSettingInfo(gameData);
        reset2(gameData);
    };

    function getPaymentGatewayData() {
        var data = {};
        Api.get('payment-gateway', data).then(resp => {
            var respData = resp.data;
            if (respData.status == 1) {
                setPaymentGatewayList(respData.data);
            } else {
                setFail(respData.message);
            }
        });
    }

    function onUpdateSt(id, stto, type, method = "") {
        const sendd = { id: id, status: stto, type };
        if(method == "COLLECT" || method == "INTENT"){
            sendd.method = method;
        }
        if(method == "ROOT_1" || method == "ROOT_2"){
            sendd.root = method;
        }
        const message = `You want active for ${type == 'pay_in' ?  `Add Money${sendd?.method ? ` ${sendd.method}` : ``}` : `Withdraw Money ${sendd?.root ? ` ${sendd.root}` : ``}`}?`;
        Alert.warning(message).then((result) => {
            if (result.isConfirmed) {
                Api.post("payment-gateway/status", sendd).then((res) => res.data).then(res => {
                    if (res.status) {
                        Alert.success(res.message);
                        getPaymentGatewayData();
                    } else {
                        Alert.error(res.message)
                    }
                }).catch(error => {
                    Alert.error(error.message)
                })
            }
        });
    }

    useEffect(() => {
        Api.post('setting').then(resp => {
            var respData = resp.data;
            if (respData.status == 1) {
                let paymentData = {
                    pg_is_card: respData.data.pg_is_card.toString(),
                    pg_is_net: respData.data.pg_is_net.toString(),
                    pg_is_upi: respData.data.pg_is_upi.toString(),
                    pg_is_googlepay: respData.data.pg_is_googlepay.toString(),
                    pg_is_phonepay: respData.data.pg_is_phonepay.toString(),
                }
                setSettingInfo(paymentData);
                setProfitRatio(respData.data.color_pro_ratio);
                reset(paymentData);

                let gameData = {
                    is_market: respData.data.is_market.toString(),
                    is_colors: respData.data.is_colors.toString(),
                    game_duration: (respData.data.game_duration ? respData.data.game_duration.toString() : "1")
                };
                setIsMarket(gameData.is_market);
                setIsColors(gameData.is_colors);
                setGameDuration(gameData.game_duration);
                setGameSettingInfo(gameData);
                reset2(gameData);
            } else {
                Alert.error(respData.message)
            }
        });
    }, []);

    useEffect(() => {
        getPaymentGatewayData();
    }, [])


    return (
        <>
            <section className="main-content marketadd_main_sec">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <MoneySetting />
                                <div className="card">
                                    <div className="card-body">
                                        <IncMsg success={success} fail={fail} />
                                        <div className="header-text">
                                            <h4 className="card-title mb-4">Payment Gateway List</h4>
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table align-middle table-nowrap mb-0">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th className="align-middle">Name</th>
                                                        <th className="align-middle">Code</th>
                                                        <th className="align-middle">Add Money</th>
                                                        <th className="align-middle">Withdraw Money</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {paymentGatewayList && paymentGatewayList.length > 0 && paymentGatewayList.map((sobj, key) => (
                                                        <tr key={key}>
                                                            <td>{sobj.name}</td>
                                                            <td>{sobj.code}</td>
                                                            <td>
                                                                {sobj.code != "GENNIEPAY" && (sobj.status ? (<FormLabel className="custom-label text-success">Active</FormLabel>) : (<FormLabel className="custom-label text-danger" onClick={() => onUpdateSt(sobj._id, '1', 'pay_in')}>Inactive</FormLabel>))}

                                                                {/* {sobj.code == "GENNIEPAY" && (sobj.is_collect ? (<>
                                                                    <FormLabel className="custom-label text-success">Collect</FormLabel>
                                                                    &nbsp;
                                                                    <FormLabel className="custom-label text-danger" onClick={() => onUpdateSt(sobj._id, '1', 'pay_in', 'INTENT')}>Intent</FormLabel>
                                                                    </>
                                                                ) : (<>
                                                                    <FormLabel className="custom-label text-danger" onClick={() => onUpdateSt(sobj._id, '1', 'pay_in', 'COLLECT')}>Collect</FormLabel>
                                                                    &nbsp;
                                                                    <FormLabel className="custom-label text-success">Intent</FormLabel>
                                                                    </>
                                                                ))} */}
                                                                {sobj.code == "GENNIEPAY" && (sobj.is_collect ? (<>
                                                                    <FormLabel className="custom-label text-success" title='Click To In Active' style={{ cursor: "pointer" }} onClick={() => onUpdateSt(sobj._id, '0', 'pay_in', 'COLLECT')}>Collect</FormLabel>
                                                                    </>
                                                                ) : (<>
                                                                    <FormLabel className="custom-label text-danger" title='Click To Active' onClick={() => onUpdateSt(sobj._id, '1', 'pay_in', 'COLLECT')}>Collect</FormLabel>
                                                                    </>
                                                                ))}
                                                                &nbsp;
                                                                {sobj.code == "GENNIEPAY" && (sobj.is_intent ? (<>
                                                                    <FormLabel className="custom-label text-success" title='Click To In Active' style={{ cursor: "pointer" }} onClick={() => onUpdateSt(sobj._id, '0', 'pay_in', 'INTENT')}>Intent</FormLabel>
                                                                    </>
                                                                ) : (<>
                                                                    <FormLabel className="custom-label text-danger" title='Click To Active' onClick={() => onUpdateSt(sobj._id, '1', 'pay_in', 'INTENT')}>Intent</FormLabel>
                                                                    </>
                                                                ))}
                                                                </td>
                                                            <td>
                                                                {sobj.code != "INDPAY" && (sobj.withdraw ? (<FormLabel className="custom-label text-success">Active</FormLabel>) : (<FormLabel className="custom-label text-danger" onClick={() => onUpdateSt(sobj._id, '1', 'pay_out')}>Inactive</FormLabel>))}

                                                                {/* Ind Pay */}
                                                                {sobj.code == "INDPAY" && (sobj.is_root1 ? (<>
                                                                    <FormLabel className="custom-label text-success" title='Click To In Active' style={{ cursor: "pointer" }} onClick={() => onUpdateSt(sobj._id, '0', 'pay_out', 'ROOT_1')}>Root 1</FormLabel>
                                                                    </>
                                                                ) : (<>
                                                                    <FormLabel className="custom-label text-danger" title='Click To Active' onClick={() => onUpdateSt(sobj._id, '1', 'pay_out', 'ROOT_1')}>Root 1</FormLabel>
                                                                    </>
                                                                ))}
                                                                &nbsp;
                                                                {sobj.code == "INDPAY" && (sobj.is_root2 ? (<>
                                                                    <FormLabel className="custom-label text-success" title='Click To In Active' style={{ cursor: "pointer" }} onClick={() => onUpdateSt(sobj._id, '0', 'pay_out', 'ROOT_2')}>Root 2</FormLabel>
                                                                    </>
                                                                ) : (<>
                                                                    <FormLabel className="custom-label text-danger" title='Click To Active' onClick={() => onUpdateSt(sobj._id, '1', 'pay_out', 'ROOT_2')}>Root 2</FormLabel>
                                                                    </>
                                                                ))}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        {/* end table-responsive  */}
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Update Payment Setting</h4>
                                        <Form key={1} className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Debit/Credit</Form.Label>
                                                        <div className="col-lg-12">
                                                            <Form.Check inline label="ON" value="1" id="on-card" type="radio" {...register("pg_is_card", { required: true })} />
                                                            <Form.Check inline label="OFF" id="off-card" type="radio" value="0" {...register("pg_is_card", { required: true })} />
                                                            <div className="error">
                                                                {errors.pg_is_card?.type === 'required' && "Debit/Credit is required"}
                                                            </div>
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-4">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Net Banking</Form.Label>
                                                        <div className="col-lg-12">
                                                            <Form.Check inline label="ON" value="1" id="on-net" type="radio" {...register("pg_is_net", { required: true })} />
                                                            <Form.Check inline label="OFF" id="off-net" type="radio" value="0" {...register("pg_is_net", { required: true })} />
                                                            <div className="error">
                                                                {errors.pg_is_net?.type === 'required' && "Net Banking is required"}
                                                            </div>
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>UPI</Form.Label>
                                                        <div className="col-lg-12">
                                                            <Form.Check inline label="ON" value="1" id="on-upi" type="radio" {...register("pg_is_upi", { required: true })} />
                                                            <Form.Check inline label="OFF" id="off-upi" type="radio" value="0" {...register("pg_is_upi", { required: true })} />
                                                            <div className="error">
                                                                {errors.pg_is_upi?.type === 'required' && "UPI is required"}
                                                            </div>
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-4">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Google Pay</Form.Label>
                                                        <div className="col-lg-12">
                                                            <Form.Check inline label="ON" value="1" id="on-googlepay" type="radio" {...register("pg_is_googlepay", { required: true })} />
                                                            <Form.Check inline label="OFF" id="off-googlepay" type="radio" value="0" {...register("pg_is_googlepay", { required: true })} />
                                                            <div className="error">
                                                                {errors.pg_is_googlepay?.type === 'required' && "Google Pay is required"}
                                                            </div>
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-4">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Phone Pay</Form.Label>
                                                        <div className="col-lg-12">
                                                            <Form.Check inline label="ON" value="1" id="on-phonepay" type="radio" {...register("pg_is_phonepay", { required: true })} />
                                                            <Form.Check inline label="OFF" id="off-phonepay" type="radio" value="0" {...register("pg_is_phonepay", { required: true })} />
                                                            <div className="error">
                                                                {errors.pg_is_phonepay?.type === 'required' && "Phone Pay is required"}
                                                            </div>
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="btn-submit">
                                                <Button className="btn btn-primary w-md" type="submit">Update Setting</Button>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">App Home Setting</h4>
                                        <Form key={2} className="needs-validation" noValidate onSubmit={handleSubmit2(onSubmitGame)}>
                                            <div className="row">
                                                <div className="col-lg-3">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Market</Form.Label>
                                                        <div className="col-lg-12">
                                                            <Form.Check inline label="ON" value="1" id="on-market" type="radio" {...register2("is_market", { required: true })} onChange={(e) => handleChangeIsMarket(e)} />
                                                            <Form.Check inline label="OFF" id="off-market" type="radio" value="0" {...register2("is_market", { required: true })} onChange={(e) => handleChangeIsMarket(e)} />
                                                            <div className="error">
                                                                {errors2.is_market?.type === 'required' && "Market is required"}
                                                            </div>
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                                {/* <div className="col-lg-3">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Color</Form.Label>
                                                        <div className="col-lg-12">
                                                            <Form.Check inline label="ON" value="1" id="on-colors" type="radio" {...register2("is_colors", { required: true })} onChange={(e) => handleChangeIsColor(e)} />
                                                            <Form.Check inline label="OFF" id="off-colors" type="radio" value="0" {...register2("is_colors", { required: true })} onChange={(e) => handleChangeIsColor(e)} />
                                                            <div className="error">
                                                                {errors2.is_colors?.type === 'required' && "Color is required"}
                                                            </div>
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Draw Time</Form.Label>
                                                        <div className="row">
                                                            <div className="col-lg-8">
                                                                <Form.Control type="number" id="game_duration" placeholder="Enter game duration" {...register2("game_duration", { required: true })} />
                                                                <div className="error">
                                                                    {errors2.game_duration?.type === 'required' && "Game duration is required"}
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                Minutes
                                                            </div>
                                                        </div>
                                                    </Form.Group>
                                                </div> */}
                                            </div>
                                            <div className="btn-submit">
                                                <Button className="btn btn-primary w-md" type="submit">Update Setting</Button>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                                {/* <UpdateColorRatioSetting setting={profitRatio} />
                                <ViewGameRatioColor /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default withRouter(Setting);