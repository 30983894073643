import React, { useEffect, useState, useMemo } from 'react';
import { Form, FormLabel, Button, Modal } from 'react-bootstrap';
import { withRouter, useParams } from "react-router-dom";
import Api from '../Services/Api';
import Alert from '../Services/Alert';
import { amountFormat, getMobileNoField } from '../Services/Custom';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import { useForm } from 'react-hook-form';
import IncMsg from './IncMsg';
require('dotenv').config();
const Cusrrency = process.env.REACT_APP_CURRENCY_SYMBOL;

export const WithdrawList = (props) => {
    var defaultWidhrawalData = {
		amount: 0.00,
		charge: "0",
        withdraw_id : 0.00
	};
    const [widhrawInfo, setwidhrawInfo] = useState(defaultWidhrawalData);
    var {id} = useParams();
    const { register, reset, handleSubmit, formState: { errors } } = useForm({ mode: "onChange", defaultValues: widhrawInfo });
    const [user_id, setUser_id] = useState(id);
    const [settingWidhrawFlag, setSettingWidhrawFlag] = useState(false);
    const [payOutData, setPayOutData] = useState({ _id: "", name: "", code: "", is_root1: false, is_root2: false });
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [userheading, setUserheading] = useState('');
    const [chargeAmount, setChargeAmount] = useState(0);
    const [finalAmount, setFinalAmount] = useState(0);
    const [hideUserCol, setHideUserCol] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [show, setShow] = useState(false);
    let [fail, setFail] = useState('');
    let [success, setSuccess] = useState('');
    const handleClose = () => setShow(false);
    const handleEditWidhrawModel = (id) => {
        Api.get('withdraw/edit/' + id).then(resp => {
			var respData = resp.data;
			if (respData.status == 1) {
				let tempData = {
					withdraw_id: respData.data._id,
					charge: respData.data.charge.toString(),
					amount: respData.data.amount,
				};
                setChargeAmount(respData.data.charge_amount.toFixed(2));
                setFinalAmount(respData.data.final_amount.toFixed(2));
				setwidhrawInfo(tempData)
				reset(tempData);
				setShow(true);
                // setSuccess(respData.message);
			} else {
				setFail(respData.message);
			}
		});
	};
    const onChangeAmountCalculation = (val) => {
        let tempData = {...widhrawInfo,amount: val};
        setwidhrawInfo(tempData)
        reset(tempData);
        let chargeAmount = (val * widhrawInfo.charge) / 100;
        let finalAmount = val - chargeAmount;
        setChargeAmount(chargeAmount.toFixed(2));
        setFinalAmount(finalAmount.toFixed(2));
    } 
    const onChangeChargeCalculation = (val) => {
        let tempData = {...widhrawInfo,charge: val};
        setwidhrawInfo(tempData)
        reset(tempData);
        let chargeAmount = (widhrawInfo.amount * val) / 100;
        let finalAmount = widhrawInfo.amount - chargeAmount;
        setChargeAmount(chargeAmount.toFixed(2));
        setFinalAmount(finalAmount.toFixed(2));
    }
    const columns = useMemo(()=>[
            {
                name: 'Initiated',
                selector: row => moment(row.createdAt).format('DD MMM, YY hh:mm A'),
            },
            {
                name: 'User',
                selector: row => (row.user_id) ? getMobileNoField(row.user_id):'-',
                omit: hideUserCol
            },
            {
                name: 'Amount',
                selector: row => row.amount+Cusrrency,
            },
            {
                name: 'Charge Amount',
                selector: row => row.charge_amount+Cusrrency,
            },
            {
                name: 'Receive Amount',
                selector: row => row.final_amount+Cusrrency,
            },
            {
                name: 'Status',
                selector: row => {
                    if (row.status == 1) 
                        return <label className="text-success" >Success</label>
                    else if (row.status == 2)
                        return <label className="text-warning" >Pending</label>
                    else if (row.status == 3)
                        return <label className="text-danger" >Cancel</label>
                    else if (row.status == 4)
                        return <label className="text-info" >Processing</label>
                },
            },
            {
                name: 'Action',
                selector: row => {
                    if (row.status == 2) 
                        return <div><button onClick={() => handleEditWidhrawModel(row._id)} className="btn btn-outline-secondary btn-sm m-1 btn-rounded waves-effect waves-light">Edit</button><br/><button onClick={() => approveRejectRequest(row._id,1,row.final_amount,row.user_id)} className="btn btn-outline-success btn-sm btn-rounded waves-effect waves-light m-1">Approve</button><br/><button onClick={() => approveRejectRequest(row._id,3,row.final_amount,row.user_id)} className="btn btn-outline-danger btn-sm btn-rounded m-1 waves-effect waves-light">Reject</button></div>
                    else
                        return '-';
                },
            },
        ]
    );
    const fetchWithdraw = async page => {
        setLoading(true);
        var sendd = { page: page, size: perPage, search: search};
        if (typeof user_id !== 'undefined' && user_id != ''){
            sendd.user_id = user_id;
            setHideUserCol(true);
        }
        const response = await Api.post('withdraws', sendd);
        if (typeof user_id !== 'undefined' && user_id != ''){
            let user_info = response.data.data.user_info[0];
            let user_name = (user_info.BankDetails.length > 0) ? user_info.BankDetails[0].user_name+' - ' : '';
            let mobile_no = (user_info.mobile_no) ? user_info.mobile_no : '';
            setUserheading(' of '+user_name+mobile_no);
        }
        setData(response.data.data.docs);
        setTotalRows(response.data.total);
        setLoading(false);
    };

    const handlePageChange = page => {
        fetchWithdraw(page);
    };

    const submitRequest = (sendData) => {
        Api.post("withdraw/approve_reject", sendData)
            .then((res) => res.data)
            .then(res => {
                if (res.status) {
                    Alert.success(res.message);
                    handlePageChange(1);
                } else {
                    Alert.error(res.message)
                }
            }).catch(error => {
                Alert.error(error.message)
            })
    };

    const approveRejectRequest = async (id, status, amount, user) => {
        let alert_msg = '';
        let mobNo = getMobileNoField(user);
        if(status == 1){
            //alert_msg = 'Are you sure to approve this request ?';
            alert_msg = 'Do you want to Authorize ' + Cusrrency+' '+amount+' for '+mobNo+'?';
        }else if(status == 3){
            //alert_msg = 'Are you sure to reject this request ?';
            alert_msg = 'Do you want to Reject ' + Cusrrency + ' ' + amount + ' for ' + mobNo + '?';
        }
        let isConfirmShown = true;
        if(status == 1 && payOutData.code == "INDPAY"){
            if(payOutData.is_root1 && payOutData.is_root2){
                isConfirmShown = false;
                Alert.chooseRootIndPay(alert_msg)
                .then((result) => {
                    console.log("result chooseRootIndPay", result);
                    if (result.isConfirmed) {
                        const sendData = { id: id, status: status, root: result?.value };
                        submitRequest(sendData);
                    }
                });
            }
        }
        if(isConfirmShown){
            Alert.warning(alert_msg).then((result) => {
                console.log("result.isConfirmed", result.isConfirmed);
                if (result.isConfirmed) {
                    const sendData = { id: id, status: status };
                    if(status == 1 && payOutData.code == "INDPAY"){
                        if(payOutData.is_root1){
                            sendData.root = 1;
                        } else if(payOutData.is_root2){
                            sendData.root = 2;
                        }
                    }
                    submitRequest(sendData);
                }
            });
        }
    };
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        var sendd = { page: page, size: perPage, search: search };
        if (typeof user_id !== 'undefined' && user_id != '') {
            sendd.user_id = user_id;
        }
        const response = await Api.post('withdraws', sendd)
        setData(response.data.data.docs);
        setPerPage(newPerPage);
        setLoading(false);
    };
    const onWidhrawUpdate = async (data) => {
		Api.post('withdraw/update', data).then((res) => res.data).then(res => {
			if (res.status) {
				Alert.success(res.message);
				handleClose();
				fetchWithdraw(1);
			} else {
				Alert.error(res.message)
			}
		}).catch(error => {
			Alert.error(error.message)
		})
	}
    const settingWidthrawRequest = async (status) => {
        let alert_msg = '';
        if(status == 1){
            alert_msg = 'Are you sure to enable this request ?';
        }else if(status == 0){
            alert_msg = 'Are you sure to disable this request ?';
        }
        Alert.warning(alert_msg).then((result) => {
            if (result.isConfirmed) {
                Api.post("withdraw/request_enabled_disabled", { status:status }).then((res) => res.data).then(res => {
                    if (res.status) {
                        if(status == 0){
                            setSettingWidhrawFlag("1");
                        }else{
                            setSettingWidhrawFlag("0");
                        }
                        Alert.success(res.message);
                        handleClose();
				        fetchWithdraw(1);
                    } else {
                        Alert.error(res.message)
                    }
                }).catch(error => {
                    Alert.error(error.message)
                })
            }
        });
    };
    const settingData = async () => {
        Api.post('setting').then((res) => res.data).then(res => {
            if (res.status) {
                const resp = res.data;
                if(resp?.payOut){
                    const { payOut } = resp;
                    setPayOutData({ _id: payOut._id, name: payOut.name, code: payOut.code, is_root1: payOut.is_root1, is_root2: payOut.is_root2 });
                }
                if(res.data.widthraw_request == 0){
                    setSettingWidhrawFlag("1");
                }else{
                    setSettingWidhrawFlag("0");
                }
            } else {
                Alert.error(res.message)
            }
        }).catch(error => {
            Alert.error(error.message)
        })
    }

    /* 18-11-2021 */
    const [search, setSearch] = useState("");
    const [isReset, setIsReset] = useState(0);
    const handleChangeSearch = (e) => {
        setSearch(e.target.value);
    };
    const handleFilter = () => {
        //userData(1);
        setIsReset(!isReset);
    };
    function handleResetFilter() {
        setSearch("");
        setIsReset(0);
    }
    /* 18-11-2021 End */
    useEffect(() => {
        settingData();
    }, []);
    useEffect(() => {
        fetchWithdraw(1);
    }, [isReset]);
  return (
    <>
      <section className="main-content userlist_main_sec">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                  <div className="card">
                      <div className="card-body">
                        <IncMsg success={success} fail={fail} />
                        <div className="header-text">
                            <h4 className="card-title mb-4">{(typeof user_id !== 'undefined' && user_id != '') ? 'Withdraws'+userheading :'Withdraws' }</h4>
                            <div className="edit-btn">
                                {(settingWidhrawFlag != 1) ? <button onClick={() => settingWidthrawRequest(settingWidhrawFlag)} className="btn btn-outline-success btn-sm btn-rounded m-1 waves-effect waves-light">Widhraw Request Enabled</button> : <button onClick={() => settingWidthrawRequest(settingWidhrawFlag)} className="btn btn-outline-danger btn-sm btn-rounded m-1 waves-effect waves-light">Widhraw Request Disabled</button>}
                                
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-md-3">
                                <input type="text" className="form-control" value={search} onChange={(e) => handleChangeSearch(e)} placeholder="Search By Mobile No" />
                            </div>
                            <div className="col-lg-3 col-md-3">
                                <button type="button" className="btn btn-outline-primary mr-4 btn-sm btn-rounded waves-effect waves-light" onClick={() => handleFilter()}>Filter</button>&nbsp;
                                <button type="button" className="btn btn-outline-secondary mr-4 btn-sm btn-rounded waves-effect waves-light" onClick={() => handleResetFilter()}>Reset</button>
                            </div>
                        </div>
                          {/* <div className="table-responsive"> */}
                                <DataTable
                                    columns={columns}
                                    data={data}
                                    progressPending={loading}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={totalRows}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    onChangePage={handlePageChange}
                                />
                          {/* </div> */}
                          {/* end table-responsive  */}
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Withdraw Request</Modal.Title>
            </Modal.Header>
            <Form className="needs-validation" noValidate onSubmit={handleSubmit(onWidhrawUpdate)}>
                <Modal.Body>
                    <div className="row">
                        <Form.Control type="hidden" {...register("withdraw_id")}/>
                        <div className="col-lg-12">
                            <Form.Group className="mb-3" controlId="amount">
                                <Form.Label>Amount</Form.Label>
                                <Form.Control type="text" placeholder="Enter amount here" className="form-control" onKeyUp={(e)=>onChangeAmountCalculation(e.target.value)} {...register("amount", { required: true })} />
                                <div className="error">
                                    {errors.amount?.type === 'required' && "Amount is required"}
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-lg-12">
                            <Form.Group className="mb-3" controlId="charge">
                                <Form.Label>Charge</Form.Label>
                                <Form.Control type="text" onKeyUp={(e)=>onChangeChargeCalculation(e.target.value)} placeholder="Enter charge amount here" className="form-control" {...register("charge", { required: true })} />
                                <div className="error">
                                    {errors.charge?.type === 'required' && "Charge is required"}
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-lg-6">
                            <Form.Group className="mb-3" controlId="charge">
                                <Form.Label>Final Amount :{finalAmount}{Cusrrency}</Form.Label>
                            </Form.Group>
                        </div>
                        <div className="col-lg-6">
                            <Form.Group className="mb-3" controlId="charge">
                                <Form.Label>Charge Amount :{chargeAmount}{Cusrrency}</Form.Label><br/>
                            </Form.Group>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" type="submit">
                        Save
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    </>
  )
}

export default withRouter(WithdrawList);